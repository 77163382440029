import {Calendar} from "@fullcalendar/core";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from '@fullcalendar/interaction';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

export class PremisesCalendarBuilder {
    constructor(calendarEl, dotNetObj) {
        
        this.calendar = new Calendar(calendarEl, {
            plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, bootstrap5Plugin ],
            themeSystem: 'bootstrap5',
            timeZone: 'local',
            initialView: 'listQtr',
            views: {
                listQtr: {
                    titleFormat: {year: 'numeric', month: 'short'},
                    type: 'listMonth',
                    duration: { months: 6 }
                }
            },
            headerToolbar: {
                left: 'title',
                center: '',
                right: 'todayCustomButton prev,next'
            },
            customButtons: {
                todayCustomButton: {
                    text: 'Today',
                    click: function() {
                        try {
                            dotNetObj.invokeMethodAsync("GoToToday");    
                        } catch (e) {
                            console.log("Error today: " + e)
                        }
                        
                    }
                }
            },
            height: 250,
            weekends: false,
            eventMouseEnter: async function (mouseEnterInfo) {
                try {
                    if (mouseEnterInfo.el.id.length === 0 ) {
                        mouseEnterInfo.el.id = `id_${mouseEnterInfo.event.id}`;
                    }

                    mouseEnterInfo.el.style.cursor  = "pointer";
                    await dotNetObj.invokeMethodAsync("HoverOverScheduleEvent", mouseEnterInfo.el.id, mouseEnterInfo.event.id);
                } catch (e) {
                    console.log("Error" + e);
                }
            },
            dayMaxEventRows: true,
            eventMaxStack: 5,
            eventDisplay: "block",
            eventSources: [
                {
                    events:
                        async function (info, successCallback, failureCallback) {
                            try {
                                const eventData = await dotNetObj.invokeMethodAsync("RerenderScheduleEvent", info.start.toJSON(), info.end.toJSON());
                                successCallback(eventData);
                            } catch (e) {
                                console.error("Error fetching event data:", e);
                                failureCallback(e);
                            }
                        }
                },
            ],
        });

        this.calendar.render();
    }

    rerenderCalendar() {
        this.calendar.refetchEvents()
    }
    
    goToToday() {
        this.calendar.today();
    }
}
