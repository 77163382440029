import {DropDownList} from '@syncfusion/ej2-dropdowns';
import {Query} from '@syncfusion/ej2-data';

export class ServiceProviderSelectJs{
    constructor(id, textName, valueName, placeholder, dotNetObject, dataFunctionName, changeEventName, showSpinnerName, hideSpinnerName, showClearButtonName, hideClearButtonName, initialValue) {
        this.dropDown = new DropDownList({
            fields: {text: textName, value: valueName},
            placeholder: placeholder,
            allowFiltering: true
        });
        
        let copy = this;
        
        this.id = id;
        this.textName = textName;
        this.valueName = valueName;
        this.dotNetObject = dotNetObject;
        this.dataFunctionName = dataFunctionName;
        this.changeEventName = changeEventName;
        this.showSpinnerName = showSpinnerName;
        this.hideSpinnerName = hideSpinnerName;
        this.showClearButtonName = showClearButtonName;
        this.hideClearButtonName = hideClearButtonName;
        this.clearIsVis = false;
        this.check = true;
        this.models = [];

        this.missingCharacterTemplate = "<span>Enter in three characters!</span>";
        this.noRecordsTemplate = "<span>No Records Found</span>";
        this.loadingTemplate = "<span>Loading ...</span>";

        this.dropDown.noRecordsTemplate = this.missingCharacterTemplate;

        this.dropDown.filtering = function (args){
            args.preventDefaultAction = true;

            if (args.text.length < 3)
            {
                copy.dropDown.noRecordsTemplate = copy.missingCharacterTemplate;

                if (!copy.check)
                {
                    copy.clearFromDataSourceUpdate = true;
                    copy.check = true;
                    copy.models = [];
                    copy.dropDown.dataSource = copy.models;
                }
            }
            else if (args.text.length > 2)
            {
                let doFilter = true;
                let refreshing = false;
                if (copy.models !== null && !copy.models.some(x => x.name === args.text) && copy.check)
                {
                    copy.check = false;
                    doFilter = false;
                    copy.dropDown.noRecordsTemplate = copy.loadingTemplate;
                    refreshing = true;
                    copy.refresh(args.text).then((res) => {
                        if(res){
                            let query = new Query();
                            query = (args.text !== "") ? query.where(copy.textName, "contains", args.text, true) : query;

                            copy.dropDown.filter(copy.models, query);
                        }
                    });
                }

                if(!refreshing)
                    copy.dropDown.noRecordsTemplate = copy.noRecordsTemplate;

                if (doFilter)
                {
                    let query = new Query();
                    query = (args.text !== "") ? query.where(copy.textName, "contains", args.text, true) : query;

                    args.updateData(copy.models, query);
                }

            }
        };

        this.currentVal = null;
        this.currentItemData = null;

        this.dropDown.change = function (args){
            if(copy.outsideError){
                copy.outsideError = false;

                if(copy.parentElem.classList.contains("tce-syncfusion-error")){
                    copy.parentElem.classList.remove("tce-syncfusion-error");
                }
            }

            if(args.value === null || args.value === undefined || args.value === 0){
                if(!copy.check){
                    copy.dropDown.noRecordsTemplate = copy.missingCharacterTemplate;
                    copy.models = [];
                    copy.dropDown.dataSource = copy.models;
                    copy.check = true;
                }

                if(copy.clearFromDataSourceUpdate){
                    copy.clearFromDataSourceUpdate = false;
                    if(copy.currentItemData !== null && copy.currentItemData !== undefined){
                        copy.elem.value = copy.currentItemData[copy.textName];
                    }
                    return;
                }

                copy.clearIsVis = false;
                copy.dotNetObject.invokeMethodAsync(copy.hideClearButtonName)
            }
            else if(!copy.clearIsVis){
                copy.clearIsVis = true;
                copy.dotNetObject.invokeMethodAsync(copy.showClearButtonName);
            }

            copy.clearFromDataSourceUpdate = false;

            copy.currentVal = args.value;
            copy.currentItemData = args.itemData;
            copy.dotNetObject.invokeMethodAsync(copy.changeEventName, args.value);
        }

        this.dropDown.dataBound = function (){
            if(copy.currentItemData !== null && copy.currentItemData !== undefined){
                copy.elem.value = copy.currentItemData[copy.textName];
            }
        }

        this.dropDown.close = function (){
            if((copy.dropDown.value === null || copy.dropDown.value === undefined || copy.dropDown.value === 0) && !copy.check){
                copy.dropDown.noRecordsTemplate = copy.missingCharacterTemplate;
                copy.models = [];
                copy.dropDown.dataSource = copy.models;
                copy.check = true;
            }
        }

        this.elem = document.getElementById(this.id);

        this.dropDown.appendTo(this.elem);

        this.parentElem = this.elem.parentElement;
        this.outsideError = false;

        this.clearFromDataSourceUpdate = false;

        if(this.parentElem !== null){
            this.parentElem.classList.add("conform-syncfusion-to-bootstrap");
        }
    }

    async refresh(name)
    {
        let doFilter = false;

        this.dotNetObject.invokeMethodAsync(this.showSpinnerName);

        let addressRecords = [];
        let responseProtoMsg = await this.dotNetObject.invokeMethodAsync(this.dataFunctionName, name);

        this.dotNetObject.invokeMethodAsync(this.hideSpinnerName);

        if (!responseProtoMsg.error)
        {
            addressRecords = responseProtoMsg.resultList;
        }

        if (name != null && name.length >= 3 && !this.check)
        {
            doFilter = true;
            this.models = addressRecords;
            this.dropDown.dataSource = this.models;
        }

        return doFilter;
    }

    showError(){
        if(!this.parentElem.classList.contains("tce-syncfusion-error")){
            this.parentElem.classList.add("tce-syncfusion-error")
            this.outsideError = true;
        }
    }

    clearError(){
        if(this.parentElem.classList.contains("tce-syncfusion-error")){
            this.parentElem.classList.remove("tce-syncfusion-error");
            this.outsideError = false;
        }
    }

    clearValue(){
        this.dropDown.clear();
        this.elem.value = null;
        this.clearIsVis = false;
    }

    getServiceProvider(val){
        if(this.currentItemData[this.valueName] === val){
            return this.currentItemData;
        }

        return this.dropDown.getDataByValue(val);
    }
}