import { DropDownList } from '@syncfusion/ej2-dropdowns';

export class DropdownListBuilder{
    constructor(id, placeholder, data, textName, valueName, dotNetObject, dotNetFunctionName) {
        this.id = id;
        this.placeholder = placeholder;
        this.data = data;
        this.textName = textName;
        this.valueName = valueName;
        this.dotNetObject = dotNetObject;
        this.dotNetFunctionName = dotNetFunctionName;
        
        this.dropDown = new DropDownList({
            dataSource: data,
            fields: {text: this.textName, value: this.valueName},
            placeholder: this.placeholder,
            showClearButton: true
        });
        
        this.dropDown.addEventListener("change", (args) => {
            let val = args.value;
            
            this.dotNetObject.invokeMethodAsync(this.dotNetFunctionName, val)
        });
    }

    appendThePicker(){
        let elem = document.getElementById(this.id);
        this.dropDown.appendTo(elem);
        this.theElem = elem;
    }
}