import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import Map from "@arcgis/core/Map"
import MapView from "@arcgis/core/views/MapView";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer"
import EsriConfig from "@arcgis/core/config";
import PopupTemplate from "@arcgis/core/PopupTemplate"
import CustomContent from "@arcgis/core/popup/content/CustomContent"
import BasemapToggle from "@arcgis/core/widgets/BasemapToggle";
import Query from "@arcgis/core/rest/support/Query";
import {UniqueValueRenderer} from "@arcgis/core/rasterRenderers";
import {SimpleFillSymbol} from "@arcgis/core/symbols";

import { setAssetPath } from "@esri/calcite-components/dist/components";
// CDN hosted assets
setAssetPath("https://cdn.jsdelivr.net/npm/@esri/calcite-components/dist/calcite/assets");
import "@esri/calcite-components/dist/calcite/calcite.css";
import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-dropdown";
import "@esri/calcite-components/dist/components/calcite-dropdown-group";
import "@esri/calcite-components/dist/components/calcite-dropdown-item";
import "@esri/calcite-components/dist/components/calcite-select";
import "@esri/calcite-components/dist/components/calcite-option";

function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;   
}

function constructWhereClauseFromSqlArray(sqlArray)
{
    let sqlString = "1 = 1";
    sqlArray.forEach(element => {
        switch (typeof element) {
            case 'string':
                sqlString = sqlString + " AND " + element;
                break;
            case 'object':
                if (element.length > 0) {
                    sqlString = sqlString + " AND (";
                    for (let i = 0; i < element.length; i++) {
                        // Note: Esri prepends an 'F' to the start of the report type column names because they are numbers and SQL doesn't like that.
                       if (i === 0) {
                        sqlString = sqlString + "F" + element[i] + " = 'True'";
                       } 
                       else {
                           sqlString = sqlString + " OR " + "F" + element[i] + " = 'True'";
                       }
                    }
                    sqlString = sqlString + ")";
                }
                else
                {
                    // UNKNOWN is what is implied by having an empty set of report types. This does not hinder the use
                    // of other filters.
                    sqlString = sqlString + " and premises_deficiency_status_code = 'UNKNOWN'";
                }
               break; 
        }
    });
    
    return sqlString;
}

export class ArcGISFullMapBuilder {

    constructor(id, dotNetObj, geoFenceURL, ahjLatitude, ahjLongitude, reportTypeIdArray, reportTypeNameArray, accessToken, featureLayerURL) {
        const SQL_dictionary={
            "Compliance Status (All)":"premises_deficiency_status_code LIKE '%'",
            "Compliant":"premises_deficiency_status_code = 'COMPLIANT'",
            "Deficient":"premises_deficiency_status_code = 'DEFICIENT'",
            "Unknown":"premises_deficiency_status_code = 'UNKNOWN'",
            "Overdue Status (All)":"(premises_is_overdue = 'True' or premises_is_overdue = 'False')",
            "Overdue":"premises_is_overdue = 'True'",
            "Not Overdue":"premises_is_overdue = 'False'",
            "Premises Status (All)":"(PremisesStatus LIKE '%' OR PremisesStatus is NULL)",
            "Active":"PremisesStatus = 'ACTIVE'",
            "Inactive":"PremisesStatus = 'INACTIVE'",
        };
        
      
        // currently everything is hard coded for testing purposes
        EsriConfig.apiKey = "AAPK611fdef280214bc390fbc948069f577euQ6HQ-y77_8KRFu1ur1d1e9K1o0qopRpCAGnZoij5_CmG0ZOIulN0KByERdimaXr";

        EsriConfig.request.interceptors.push({
            // set the `urls` property to the URL of the FeatureLayer so that this
            // interceptor only applies to requests made to the FeatureLayer URL
            urls: featureLayerURL,
            // use the BeforeInterceptorCallback to add token to query
            before: function(params) {
                params.requestOptions.query = params.requestOptions.query || {};
                params.requestOptions.query.token = accessToken;
            }
        });

        const map = new Map({
            basemap: "streets-navigation-vector"
        });

        const view = new MapView({
            container: id, // div element
            map: map,
            center: [parseFloat(ahjLongitude), parseFloat(ahjLatitude)],
            zoom: 12,
            popup: {
                dockEnabled: true,
                dockOptions: {
                    buttonEnabled: false,
                    breakpoint: false,
                },
            }
        });

        const basemapToggle = new BasemapToggle({
            view: view,  // The view that provides access to the map's "streets-vector" basemap
            nextBasemap: "hybrid"  // Allows for toggling to the "hybrid" basemap
        });

        view.ui.add(basemapToggle, "bottom-left");

        const mapLayer = new FeatureLayer({
            url: featureLayerURL
        });

        const customContent = new CustomContent({
            outFields: ["*"],
            creator: (event) => {
                const name = `${event.graphic.attributes["premises_full_address_block"]}`;
                const link = `<a href=${window.location.origin}/premises/show/${event.graphic.attributes["premises_id"]}><b>View</b></a>`;
                const deficiencyCode = `${event.graphic.attributes["premises_deficiency_status_code"]}`
                const isOverdue = `${event.graphic.attributes["premises_is_overdue"]}` 

                return `<div class="container-fluid p-2">
                            <div class="row">
                                <div class="col">Address</div>
                                <div class="col">${name}</div>
                            </div>
                            <div class="row">
                                <div class="col">Compliance Status</div>
                                <div class="col">${deficiencyCode}</div>
                            </div>
                            <div class="row">
                                <div class="col">Is Overdue</div>
                                <div class="col">${isOverdue}</div>
                            </div>
                            <div class="row">
                                <div class="col">Link</div>
                                <div class="col">${link}</div>
                        </div>
                    </div>`
            },
        });

        mapLayer.popupTemplate = new PopupTemplate({
            outFields: ["*"],
            title: "{premises_name}",
            content: [customContent],
        });

        let whereClause = "";
        
        const filterDiv = document.createElement("div");
        filterDiv.setAttribute("style", "width: 200px");

        // filterArray index = 0
        const complianceStatusSQL = ["Compliance Status (All)", "Compliant",  "Deficient", "Unknown"];
        const complianceStatusSelect = document.createElement("calcite-select");
        complianceStatusSelect.setAttribute("id", "complianceStatusSelectDiv");
        complianceStatusSQL.forEach((option) => {
            const calciteOption = document.createElement("calcite-option");
            calciteOption.innerText = option;
            calciteOption.value = option;
            complianceStatusSelect.appendChild(calciteOption);
        });

        complianceStatusSelect.addEventListener('calciteSelectChange', (event) => {
            filterArray[0] = SQL_dictionary[event.target.value];
            whereClause = constructWhereClauseFromSqlArray(filterArray);
            mapLayer.definitionExpression = whereClause;
        });
        
        // filterArray index = 1
        const premisesOverdueSQL = ["Overdue Status (All)", "Overdue", "Not Overdue"]
        const premisesOverdueSelect = document.createElement("calcite-select");
        premisesOverdueSelect.setAttribute("id", "premisesOverdueSelectDiv");
        premisesOverdueSQL.forEach((option) => {
            const calciteOption = document.createElement("calcite-option");
            calciteOption.innerText = option;
            calciteOption.value = option;
            premisesOverdueSelect.appendChild(calciteOption);
        });

        premisesOverdueSelect.addEventListener('calciteSelectChange', (event) => {
            filterArray[1] = SQL_dictionary[event.target.value];
            whereClause = constructWhereClauseFromSqlArray(filterArray);
            mapLayer.definitionExpression = whereClause;
        });

        // filterArray index = 2
        const activeOptions = ["Premises Status (All)", "Active", "Inactive"];
        const activeInactiveSelect = document.createElement("calcite-select");
        activeInactiveSelect.setAttribute("id", "activeInactiveSelectDiv");
        activeOptions.forEach((option) => {
            const calciteOption = document.createElement("calcite-option");
            calciteOption.innerText = option;
            calciteOption.value = option;
            activeInactiveSelect.appendChild(calciteOption);
        });

        activeInactiveSelect.addEventListener('calciteSelectChange', (event) => {
            filterArray[2] = SQL_dictionary[event.target.value];
            whereClause = constructWhereClauseFromSqlArray(filterArray);
            mapLayer.definitionExpression = whereClause;
        });
        filterDiv.appendChild(complianceStatusSelect);
        filterDiv.appendChild(premisesOverdueSelect);
        filterDiv.appendChild(activeInactiveSelect);
        view.ui.add(filterDiv, "bottom-right");


        // filterArray index = 3
        const reportTypeDiv = document.createElement("div");

        const reportTypeDropdown = document.createElement("calcite-dropdown");
        reportTypeDropdown.setAttribute("id", "reportTypeSelectDiv");
        reportTypeDropdown.setAttribute("close-on-select-disabled", "true");
        reportTypeDropdown.setAttribute("style", "width: 200px");
        
        const reportTypeButton = document.createElement("calcite-button")
        reportTypeButton.setAttribute("alignment", "icon-end-space-between");
        reportTypeButton.setAttribute("slot", "trigger");
        reportTypeButton.setAttribute("width", "full");
        reportTypeButton.setAttribute("kind", "inverse");
        reportTypeButton.setAttribute("icon-end", "chevron-down");
        reportTypeButton.setAttribute("appearance", "outline-fill");
        reportTypeButton.innerText = "Report Types";
        reportTypeDropdown.appendChild(reportTypeButton);
        
        const reportTypeDropDownGroup = document.createElement("calcite-dropdown-group")
        reportTypeDropDownGroup.setAttribute("selection-mode", "multiple");
        reportTypeDropdown.appendChild(reportTypeDropDownGroup);

        const selectAllDropdownItem = document.createElement("calcite-dropdown-item");
        selectAllDropdownItem.innerText = "Select All";
        selectAllDropdownItem.value = "selectAll";
        selectAllDropdownItem.setAttribute("id",  "selectMeButton");
        reportTypeDropDownGroup.appendChild(selectAllDropdownItem);
        
        selectAllDropdownItem.addEventListener('calciteDropdownItemSelect', (event) => {
            for (let i = 2; i < reportTypeDropDownGroup.children.length; i++) {
                let child = reportTypeDropDownGroup.children[i];
                child.selected = true;
            }
        });
        
        const unSelectAllDropdownItem = document.createElement("calcite-dropdown-item");
        unSelectAllDropdownItem.innerText = "Clear all";
        unSelectAllDropdownItem.value = "clearAll";
        unSelectAllDropdownItem.setAttribute("id",  "unSelectMeButton");
        reportTypeDropDownGroup.appendChild(unSelectAllDropdownItem);

        unSelectAllDropdownItem.addEventListener('calciteDropdownItemSelect', (event) => {
            for (let i = 2; i < reportTypeDropDownGroup.children.length; i++) {
                let child = reportTypeDropDownGroup.children[i];
                child.selected = false;
            }
        });
       
        let initialArray = [];
        for (let i = 0; i < reportTypeIdArray.length; i++)
        {
            let dropDownItem = document.createElement("calcite-dropdown-item");
            dropDownItem.innerText = reportTypeNameArray[i];
            dropDownItem.value = reportTypeIdArray[i];
            initialArray.push(reportTypeIdArray[i]);
            dropDownItem.setAttribute("selected",  "true");
            dropDownItem.setAttribute("id",  `thingy${i}`);
            reportTypeDropDownGroup.appendChild(dropDownItem);
        }
        
        reportTypeDropdown.addEventListener('calciteDropdownSelect', (event) => {
            // These must be set false here in order to have the desired effect of these acting more as buttons
            reportTypeDropDownGroup.children[0].selected = false; // Select All
            reportTypeDropDownGroup.children[1].selected = false; // Unselect All
            
            let selectionValues = []; 
            // The 'selectedItems' list does not update when manually setting the 'selected' value,
            // thus the need for the if statement to actually check if the item is selected
            reportTypeDropdown.selectedItems.forEach((item) => {
                if (item.selected === true)
                    selectionValues.push(item.value);
            });

            filterArray[3] = selectionValues; // Array of report type ids

            whereClause = constructWhereClauseFromSqlArray(filterArray);
            mapLayer.definitionExpression = whereClause;
        });

        reportTypeDiv.appendChild(reportTypeDropdown);
        view.ui.add(reportTypeDiv, "top-right");

        let filterArray = new Array(4);
        filterArray[3] = initialArray;
        mapLayer.definitionExpression = constructWhereClauseFromSqlArray(filterArray);
        
        let geoJsonLayer = null;
        if (geoFenceURL !== null)
        {
            geoJsonLayer = new GeoJSONLayer({
                url: geoFenceURL,
            });
            
            void geoJsonLayer.when(function () {
                if (geoJsonLayer.getField('fill')) {
                    const query = new Query();
                    query.returnDistinctValues = true;
                    query.returnGeometry = false;
                    query.outFields = ['fill', 'stroke', 'fill_opacity', 'stroke_opacity', 'stroke_width'];
                    geoJsonLayer.queryFeatures(query).then(function(results) {
                        const renderer = new UniqueValueRenderer();
                        renderer.field = 'fill';
                        renderer.defaultSymbol = new SimpleFillSymbol();
                        results.features.forEach((element, index) => {
                            let rgb = hexToRgb(element.attributes['fill']);
                            let strokeRgb = hexToRgb(element.attributes['stroke']);
                            
                            let rgbaText = "rgba(3, 35, 61, .5)";
                            let rgbaStrokeText = "rgba(3, 35, 61, 1)";
                            if(rgb !== null){
                                rgbaText = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${element.attributes['fill_opacity']})`;
                            }
                            if(strokeRgb !== null){
                                rgbaStrokeText = `rgba(${strokeRgb.r}, ${strokeRgb.g}, ${strokeRgb.b}, ${element.attributes['stroke_opacity']})`;
                            }
                            renderer.addUniqueValueInfo({
                                value: element.attributes['fill'],
                                symbol: {
                                    type: "simple-fill",
                                    color: rgbaText,
                                    outline: {
                                        width: element.attributes['stroke_width'],
                                        color: rgbaStrokeText
                                    }
                                }
                            });
                        }); 
                        geoJsonLayer.renderer = renderer;
                    }); 
                } else {
                    geoJsonLayer.renderer = {
                        type: "simple",
                        symbol: {
                            type: "simple-fill",
                            color: "rgba(3, 35, 61, .5)",
                            outline: {
                                width: 3,
                                color: "#01223d"
                            }
                        }
                    };
                }
            }, function(error) {
                console.log("MAP FAILURE", error)
            });
        }

        map.add(mapLayer, 0);
        if (geoFenceURL !== null)
            map.add(geoJsonLayer,0);
    }
}