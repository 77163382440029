export class NumberMaskBuilder{
    constructor(id, placeholder, length, dotNetObjectReference, dotNetFunctionChange, dotNetFunctionInput) {
        this.id = id;
        this.elem = document.getElementById(this.id);
        this.placeholder = placeholder;
        this.length = length;
        this.dotNetObjectReference = dotNetObjectReference;
        this.dotNetFunctionChange = dotNetFunctionChange;
        this.dotNetFunctionInput = dotNetFunctionInput;

        const reference = this;

        this.elem.addEventListener('focus', () => reference.setAfterLastCharacter(true))
        this.elem.addEventListener('input', () => reference.formatInput());
        this.elem.addEventListener('change', () => reference.updateBlazor());

        this.setMask();
    }
    updateBlazor(){
        const inputField = this.elem;

        this.dotNetObjectReference.invokeMethodAsync(this.dotNetFunctionChange, this.conditionInputForBlazor(inputField.value));
    }
    updateValue(val){
        const inputField = this.elem;
        inputField.value = this.conditionInput(val);
    }
    setAfterLastCharacter(inFocus = false) {
        const inputField = this.elem;

        let pos = inputField.value.indexOf(this.placeholder);

        if (inFocus) {
            setTimeout(() => inputField.setSelectionRange(pos, pos), 1);
        } else {
            inputField.setSelectionRange(pos, pos);
        }
    }
    setMask(){
        const inputField = this.elem;
        let maskString = "";

        for(let i = 0; i < this.length; i++){
            maskString += this.placeholder;
        }

        inputField.value = maskString;
    }
    formatInput(){
        const inputField = this.elem;
        inputField.value = this.conditionInput(inputField.value);
        this.setAfterLastCharacter();
        this.dotNetObjectReference.invokeMethodAsync(this.dotNetFunctionInput, this.conditionInputForBlazor(inputField.value));
    }
    conditionInputForBlazor(val){
        return val.replace(/[^\d]/g, '');
    }
    conditionInput(val){
        if(val === undefined || val === null) return val;

        let trimmed = val.replace(/[^\d]/g, '');
        const trimmedLength = trimmed.length;

        for(let i = 0; i < this.length - trimmedLength; i++){
            trimmed += this.placeholder;
        }

        return trimmed.slice(0, this.length);
    }
}