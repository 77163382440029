import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import Map from "@arcgis/core/Map"
import MapView from "@arcgis/core/views/MapView";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer"
import EsriConfig from "@arcgis/core/config";
import PopupTemplate from "@arcgis/core/PopupTemplate"
import CustomContent from "@arcgis/core/popup/content/CustomContent"
import BasemapToggle from "@arcgis/core/widgets/BasemapToggle";

export class ArcGISMapBuilder {
    constructor(id, dotNetObj, geoFenceURL, ahjLatitude, ahjLongitude, accessToken, featureLayerURL) {
        EsriConfig.apiKey = "AAPK611fdef280214bc390fbc948069f577euQ6HQ-y77_8KRFu1ur1d1e9K1o0qopRpCAGnZoij5_CmG0ZOIulN0KByERdimaXr";

        EsriConfig.request.interceptors.push({
            // set the `urls` property to the URL of the FeatureLayer so that this
            // interceptor only applies to requests made to the FeatureLayer URL
            urls: featureLayerURL,
            // use the BeforeInterceptorCallback to add token to query
            before: function(params) {
                params.requestOptions.query = params.requestOptions.query || {};
                params.requestOptions.query.token = accessToken;
            }
        });

        const map = new Map({
            basemap: "streets-navigation-vector" // Might need to put arcigs/ in front?
        });

        const view = new MapView({
            container: id, // div element
            map: map,
            center: [parseFloat(ahjLongitude), parseFloat(ahjLatitude)],
            zoom: 13,
            popup: {
                dockEnabled: true,
                dockOptions: {
                    buttonEnabled: false,
                    breakpoint: false,
                },
            }
        });

        const basemapToggle = new BasemapToggle({
            view: view,  // The view that provides access to the map's "streets-vector" basemap
            nextBasemap: "hybrid"  // Allows for toggling to the "hybrid" basemap
        });

        view.ui.add(basemapToggle, "bottom-left");

        //Trailheads feature layer (points)
        const mapLayer = new FeatureLayer({
            url: featureLayerURL
        });

        const customContent = new CustomContent({
            outFields: ["*"],
            creator: (event) => {
                const name = `${event.graphic.attributes["premises_full_address_block"]}`;
                const link = `<a href=https://tce-pwa.thecomplianceengine.com/premises/show/${event.graphic.attributes["premises_id"]}><b>View</b></a>`;
                const deficiencyCode = `${event.graphic.attributes["premises_deficiency_status_code"]}`

                return `<div class="container-fluid p-2">
                            <div class="row">
                                <div class="col">Address</div>
                                <div class="col">${name}</div>
                            </div>
                            <div class="row">
                                <div class="col">Status</div>
                                <div class="col">${deficiencyCode}</div>
                            </div>
                            <div class="row">
                                <div class="col">Link</div>
                                <div class="col">${link}</div>
                        </div>
                    </div>`
            },
        });

        mapLayer.popupTemplate = new PopupTemplate({
            outFields: ["*"],
            title: "{premises_name}",
            content: [customContent],
        });

        let geoJsonLayer = null;
        if (geoFenceURL !== null)
        {
            geoJsonLayer = new GeoJSONLayer({
                url: geoFenceURL,
                renderer: {
                    type: "simple",
                    symbol: {
                        type: "simple-fill",
                        size: 6,
                        color: "rgba(3, 35, 61, .5)",
                        outline: {
                            width: 3,
                            color: "#01223d"
                        }
                    }
                }
            });
        }


        map.add(mapLayer, 0);
        if (geoFenceURL !== null)
            map.add(geoJsonLayer,0);
    }
}