import { Chart, StackingAreaSeries, Category, Tooltip, Legend, DateTime } from '@syncfusion/ej2-charts';

Chart.Inject(StackingAreaSeries, Tooltip, Legend, Category, DateTime);

const ChartColors = [
    "#003399", "#ff9933", "#AA4643",
    "#2f7ed8", "#80699B", "#8bbc21", 
    "#910000", "#1aadce", "#492970", 
    "#f28f43", "#77a1e5", "#a6c96a", 
    "#A47D7C", "#e4d354", "#5f5449"
]

export class StackedAreaChartBuilder{
    constructor(id, xName, yName, xAxisLabel, yAxisLabel, title) {
        this.id = id;
        this.xName = xName;
        this.yName = yName;
        this.firstRender = true;
        this.the_chart = new Chart({
            primaryXAxis: {
                valueType: 'DateTime',
                intervalType: 'Months',
                rangePadding: 'Additional',
                minimum: Date.now(),
                maximum: Date.now(),
                title: xAxisLabel
            },
            primaryYAxis: {
                title: yAxisLabel
            },
            series: [
            ],
            title: title,
            legendSettings: {
                visible: true,
                position:'Bottom'
            },
            tooltip: {
                enable: true,
                shared: true
            },
            palettes: ChartColors
        });
    }

    appendTheChart(id){
        let elem = document.getElementById(id);
        this.the_chart.appendTo(elem);
    }

    addData(newData, updatingDateTime){
        if(newData === null || newData === undefined)
            return;
        
        let minDate;
        let maxDate;
        
        if(newData.length === 0){
            minDate = new Date(Date.now());
            maxDate = new Date(Date.now());
        }
        else{
            minDate = new Date(newData[0].minDate);
            maxDate = new Date(newData[0].maxDate);
        }
        
        this.the_chart.clearSeries();

        let doAnimation = this.firstRender || updatingDateTime;

        newData.forEach((element) => {
            let curMin = new Date(element.minDate);
            let curMax = new Date(element.maxDate);
            
            if(minDate.getTime() > curMin.getTime())
                minDate = curMin;
            if(maxDate.getTime() < curMax.getTime())
                maxDate = curMax;
            
            this.the_chart.addSeries([{
                type: 'StackingArea',
                dataSource: element.data,
                xName: this.xName,
                yName: this.yName,
                name: element.name,
                animation: {
                    delay: 300,
                    duration: 1000,
                    enable: doAnimation
                }
            }]);
        });
        
        let paddedMinDate = new Date(minDate.setMonth(minDate.getMonth() - 1));
        let paddedMaxDate = new Date(maxDate.setMonth(maxDate.getMonth() + 1));
        
        this.the_chart.primaryXAxis.minimum = paddedMinDate;
        this.the_chart.primaryXAxis.maximum = paddedMaxDate;

        if(this.firstRender)
            this.firstRender = false

        this.the_chart.refresh();
    }
}