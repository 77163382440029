const maxTimePerChunk = 50;
const context = window;

export function processLargeArray(array, fn, done){
    let index = 0;
    
    function now(){
        return new Date().getTime();
    }
    
    function doChunk(){
        let start = now();
        
        while(index < array.length && (now() - start) <= maxTimePerChunk){
            fn.call(context, array[index], index, array);
            ++index;
        }
        if(index < array.length){
            setTimeout(doChunk, 10)
        }
        else{
            done.call(context);
        }
    }
    
    setTimeout(doChunk, 10);
}