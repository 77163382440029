export class ObservedObjectBuilder{
    constructor(id, dotNetObjectReference, dotNetFunctionName, itemKey) {
        this.id = id;
        this.dotNetObjectReference = dotNetObjectReference;
        this.dotNetFunctionName = dotNetFunctionName;
        this.itemKey = itemKey;
        
        this.calledFunction = false;
        
        let copy = this;
        
        this.observer = new IntersectionObserver(function(entries){
            if(entries[0].isIntersecting && !copy.calledFunction){
                copy.calledFunction = true;
                copy.dotNetObjectReference.invokeMethodAsync(copy.dotNetFunctionName, copy.itemKey);
            }
        }, { threshold: [0.7] });
        
        let elem = document.getElementById(this.id);
        
        if(elem){
            this.observer.observe(elem);
        }
    }
}