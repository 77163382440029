﻿import BasemapToggle from "@arcgis/core/widgets/BasemapToggle";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer"
import Fullscreen from "@arcgis/core/widgets/Fullscreen";
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import Graphic from "@arcgis/core/Graphic"
import Map from "@arcgis/core/Map"
import MapView from "@arcgis/core/views/MapView";
import PictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol";
import Query from "@arcgis/core/rest/support/Query";

import {Point} from "@arcgis/core/geometry";
import {processLargeArray} from "../HelperFunctions/LargeArrayProcessing";
import {SimpleFillSymbol} from "@arcgis/core/symbols";
import {UniqueValueRenderer} from "@arcgis/core/rasterRenderers";

import deficientImg from "../../../wwwroot/images/flame_16x16.png";
import shieldImg from "../../../wwwroot/images/shield_16x16.png";
import unknownImg from "../../../wwwroot/images/unknown_16x16.png";

const deficientIcon = new PictureMarkerSymbol(deficientImg, "16px", "16px");
const compliantIcon = new PictureMarkerSymbol(shieldImg, "16px", "16px");
const unknownIcon = new PictureMarkerSymbol(unknownImg, "16px", "16px");

const premiseShowString = '/Premises/Show/';

function hexToRgb(hex) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

export class EsriFullMapBuilder {
    constructor(id, dotNetObj) {
        const map = new Map({
            basemap: "streets-navigation-vector"
        });

        const view = new MapView({
            map: map,
            container: id, // Div element
        });

        const basemapToggle = new BasemapToggle({
            view: view,
            nextBasemap: "hybrid"
        });

        view.ui.add(basemapToggle, "bottom-left");

        const fullscreen = new Fullscreen({
            view: view
        });

        view.ui.add(fullscreen, "top-right");

        this.the_map = map;
        this.the_view = view;
        this.the_layer = null;
        this.geo_layer = null;
        this.graphics = [];

        this.dotNetObj = dotNetObj;

        this.the_view.dockEnabled = true;
    }

    #foundGeo(){
        let foundGeo = false;

        this.the_map.layers.forEach((layer) => {
            if(layer instanceof GeoJSONLayer)
                foundGeo = true;
        });

        return foundGeo
    }

    ClearFeatureLayer(){
        if(this.the_map.layers.length === 1){
            if(this.#foundGeo()){
                this.the_layer = null;
                return;
            }
        }

        this.the_map.layers.pop();
        this.the_layer = null;
    }
#addAndZoom(wrapper){
        if (this.geo_layer !== null && !this.#foundGeo())
            this.the_map.layers.add(this.geo_layer);

        if (this.graphics.length !== 0) {
            this.the_map.layers.add(this.the_layer);

            if(wrapper.forPremises){
                let pt = new Point({
                    latitude: wrapper.premisesLatitude,
                    longitude: wrapper.premisesLongitude
                })
                this.the_view.goTo({
                    target: pt,
                    zoom: 15
                });
            }
            else{
                // console.log(`source: ${this.the_layer.source}`);

                this.the_view.goTo(this.the_layer.source);
            }
        } else {
            if(wrapper.forPremises){
                let pt = new Point({
                    latitude: wrapper.premisesLatitude,
                    longitude: wrapper.premisesLongitude
                })
                this.the_view.goTo({
                    target: pt,
                    zoom: 15
                });
            }
            else if (this.#foundGeo()) {
                let pt = new Point({
                    latitude: wrapper.ahjLatitude,
                    longitude: wrapper.ahjLongitude
                })
                this.the_view.goTo({
                    target: pt,
                    zoom: 8
                });
            }
        }
    }
    
    AddPointsToFeatureLayer(wrapper) {
        this.graphics = [];

        if(this.the_layer !== null)
            return;

        let points = wrapper.messages;

        if (wrapper.geoJsonInfo !== null && this.geo_layer === null) {
            this.geo_layer = new GeoJSONLayer({
                url: wrapper.geoJsonInfo
            });

            let instance = this;
            this.geo_layer.when(function() {
                if (instance.geo_layer.getField('fill')) {
                    const query = new Query();
                    query.returnDistinctValues = true;
                    query.returnGeometry = false;
                    query.outFields = ['fill', 'stroke', 'fill_opacity', 'stroke_opacity', 'stroke_width'];
                    instance.geo_layer.queryFeatures(query).then(function(results) {
                        const renderer = new UniqueValueRenderer();
                        renderer.field = 'fill';
                        renderer.defaultSymbol = new SimpleFillSymbol();
                        results.features.forEach((element, index) => {
                            let rgb = hexToRgb(element.attributes['fill']);
                            let strokeRgb = hexToRgb(element.attributes['stroke']);

                            let rgbaText = "rgba(3, 35, 61, .5)";
                            let rgbaStrokeText = "rgba(3, 35, 61, 1)";
                            if(rgb !== null){
                                rgbaText = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${element.attributes['fill_opacity']})`;
                            }
                            if(strokeRgb !== null){
                                rgbaStrokeText = `rgba(${strokeRgb.r}, ${strokeRgb.g}, ${strokeRgb.b}, ${element.attributes['stroke_opacity']})`;
                            }
                            renderer.addUniqueValueInfo({
                                value: element.attributes['fill'],
                                symbol: {
                                    type: "simple-fill",
                                    color: rgbaText,
                                    outline: {
                                        width: element.attributes['stroke_width'],
                                        color: rgbaStrokeText
                                    }
                                }
                            });
                        }); // end forEach 
                        instance.geo_layer.renderer = renderer;
                    }); // end query
                } else {
                    instance.geo_layer.renderer = {
                        type: "simple",
                        symbol: {
                            type: "simple-fill",
                            color: "rgba(3, 35, 61, .5)",
                            outline: {
                                width: 3,
                                color: "#01223d"
                            }
                        }
                    };
                }
            }, function(error) {
                console.log("Something unexpected", error)
            });
        }

        processLargeArray(points,(point) => {
            this.graphics.push(new Graphic({
                geometry: {
                    type: "point",
                    longitude: point.longitude,
                    latitude: point.latitude
                },
                symbol: {
                    // autocasts as new SimpleMarkerSymbol()
                    type: "simple-marker",
                    color: [226, 119, 40],
                    outline: {
                        // autocasts as new SimpleLineSymbol()
                        color: [255, 255, 255],
                        width: 2
                    }
                },
                attributes: {
                    ObjectID: point.premisesId,
                    Status: getEsriIcon(point.complianceStatus),
                    PremisesName: point.premisesName,
                    Address: point.premisesFullAddress,
                    Link: premiseShowString + point.premisesId
                }
            }));
        }, () => {
            const popupPremise = {
                content: premisePopup,
                outFields: ["*"],
                fieldInfos: [{
                    fieldName: "Status"
                },
                    {
                        fieldName: "PremisesName"
                    },
                    {
                        fieldName: "Address"
                    },
                    {
                        fieldName: "Link"
                    }
                ]
            }

            let brycer_renderer = {
                type: "unique-value",
                field: "Status",
                uniqueValueInfos: [
                    {
                        value: "UNKNOWN",
                        label: "Unknown",
                        symbol: unknownIcon
                    },
                    {
                        value: "COMPLIANT",
                        label: "Compliant",
                        symbol: compliantIcon
                    },
                    {
                        value: "DEFICIENT",
                        label: "Deficient",
                        symbol: deficientIcon
                    },
                    {
                        value: "PAST_DUE",
                        label: "Past Due",
                        symbol: deficientIcon
                    }
                ]
            }
            let layer = new FeatureLayer({
                fields: [
                    {
                        name: "ObjectID",
                        alias: "ObjectID",
                        type: "oid"
                    },
                    {
                        name: "Status",
                        alias: "Status",
                        type: "string"
                    },
                    {
                        name: "PremisesName",
                        alias: "PremisesName",
                        type: "string"
                    },
                    {
                        name: "Address",
                        alias: "Address",
                        type: "string"
                    },
                    {
                        name: "Link",
                        alias: "Link",
                        type: "string"
                    }
                ],
                objectIdField: "ObjectID",
                source: this.graphics,
                renderer: brycer_renderer,
                popupTemplate: popupPremise
            })

            layer.featureReduction = {
                type: "cluster",
                symbol: {
                    type: "simple-marker",
                    color: "#FAF9F6",
                    size: 5,
                    outline: {
                        // autocasts as new SimpleLineSymbol()
                        color: "rgba(222, 221, 217, .5)",
                        width: 5
                    }
                },
                clusterRadius: "30px",
                clusterMinSize: "30px",
                labelingInfo: [{
                    deconflictionStrategy: "static",
                    labelExpressionInfo: {
                        expression: "Text($feature.cluster_count, '#,###')"
                    },
                    symbol: {
                        type: "text",
                        color: "#000000"
                    },
                    labelPlacement: "center-center",
                }]
            }

            this.the_layer = layer;

            if (this.the_view.ready){
                this.#addAndZoom(wrapper);
            }
            else {
                let instance = this;

                this.the_view.when(function(){
                    instance.#addAndZoom(wrapper);
                })
            }
            this.dotNetObj.invokeMethodAsync("DoneRendering");
        });
    }
}

function getEsriIcon(complianceStatus) {
    switch (complianceStatus) {
        case "UNKNOWN":
        case "COMPLIANT":
        case "DEFICIENT":
        case "PAST_DUE":
            // should be removed when DB is fixed 
            return complianceStatus;
        default:
            return "UNKNOWN";
    }
}

function premisePopup(feature){
    let div = document.createElement('div');
    div.classList.add("container-fluid");
    div.classList.add("big-map-height-box");

    let link = document.createElement('a');

    link.innerText = "view";
    link.setAttribute('href', feature.graphic.attributes.Link);

    let rowDiv = document.createElement('div');
    rowDiv.classList.add("row");
    rowDiv.classList.add("margin-spacer");

    let col_5_div = document.createElement('div');
    col_5_div.classList.add("col-md-5");
    col_5_div.innerHTML = "<b>Link</b>";

    let col_7_div = document.createElement('div');
    col_7_div.classList.add("col-md-7");

    col_7_div.appendChild(link);

    rowDiv.appendChild(col_5_div);

    rowDiv.appendChild(col_7_div);

    div.innerHTML = "<div class='row margin-spacer'><div class='col-md-5'><b>Premises</b></div><div class='col-md-7'>"+ feature.graphic.attributes.PremisesName +"</div></div>" +
        "<div class='row margin-spacer'><div class='col-md-5'><b>Address</b></div><div class='col-md-7'>" + feature.graphic.attributes.Address + "</div></div>" +
        "<div class='row margin-spacer'><div class='col-md-5'><b>Status</b></div><div class='col-md-7'>" + feature.graphic.attributes.Status + "</div></div>";

    div.appendChild(rowDiv);

    return div;
}
