import {
    AccumulationChart,
    AccumulationDataLabel,
    AccumulationLegend,
    AccumulationTooltip,
    Export,
    PieSeries
} from '@syncfusion/ej2-charts';

AccumulationChart.Inject(AccumulationDataLabel, AccumulationLegend, AccumulationTooltip, Export, PieSeries);

export class PieChartBuilder {
    constructor(id, xName, yName, includeDataLabel, textName, title, pointColorName, includeLegend, legendPosition, legendAlignment) {
        this.id = id;
        this.xName = xName;
        this.yName = yName;
        this.includeDataLabel = includeDataLabel;
        this.textName = textName;
        this.pointColorName = pointColorName;

        this.firstRender = true;
        this.the_pie_chart = new AccumulationChart({
            series: [],
            tooltip: {
                enable: true
            },
            enableSmartLabels: true,
            height: '100%'
        })
        if (title !== null) {
            this.the_pie_chart.title = title;
        }

        this.the_pie_chart.legendSettings = {
            position: legendPosition,
            alignment: legendAlignment,
            visible: includeLegend,
        };

        if (legendPosition !== "Bottom") {
            this.the_pie_chart.legendSettings.width = "35%";
        }
    }

    appendTheChart(id) {
        let elem = document.getElementById(id);
        this.the_pie_chart.appendTo(elem);
    }

    addData(newData, updatingDateTime) {
        let tempX = this.xName;
        let tempY = this.yName;
        let textName = this.textName;
        let pointColorName = this.pointColorName;
        let isFirst = this.firstRender || updatingDateTime;

        this.the_pie_chart.series = [{
            dataSource: newData,
            type: 'Pie',
            xName: tempX,
            yName: tempY,
            legendShape: 'Circle',
            innerRadius: '40%',
            dataLabel: {visible: this.includeDataLabel, name: textName, position: 'Outside'},
            pointColorMapping: pointColorName,
            animation: {
                delay: 300,
                duration: 1000,
                enable: isFirst
            }
        }];

        if (this.firstRender)
            this.firstRender = false;

        this.the_pie_chart.refresh();
    }
    
    exportToPng() {
        this.the_pie_chart.export("PNG", "chart");
    }

    exportToPdf() {
        this.the_pie_chart.export("PDF", "chart");
    }
}