import {format} from 'date-fns';
export class DeficientReportRenderObject{
    constructor(id, reportDetail, isServiceProvider, serviceProviderId) {
        this.id = id;
        this.reportDetail = reportDetail;
        this.elem = document.getElementById(id);
        this.isServiceProvider = isServiceProvider;
        this.serviceProviderId = serviceProviderId;
        this.deficiencyList = new DeficiencyListRenderObject(`list-${id}`, this.elem);
        
        this.createAndSet();
    }
    
    createAndSet(){
         let row1 = document.createElement("div");
         row1.classList.add("row");
         
         let nowDate = new Date(Date.now());
         let reportDate = new Date(this.reportDetail.serviceDate);

         let timeDifference = nowDate.getTime() - reportDate.getTime();
         let totalDays = Math.abs(timeDifference / (1000 * 3600 * 24)); // it returns negative value if start date < end date
         totalDays = Math.floor(totalDays); // to get complete daysMilliSeconds = 1000 * 60 * 6
         
         row1.innerHTML = `
                <div class="col-md-4">
                    <div class="row">
                        <div class="col-6"><span><b>Report:</b></span></div>
                        <div class="col-6 d-flex flex-row">
                            <span>${this.reportDetail.reportNumber}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="row">
                        <div class="col-6"><span><b>Review Date:</b></span></div>
                        <div class="col-6">
                            <span>${ format(reportDate, "MM/dd/yyyy")} (${totalDays} days ago)</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="row">
                        <div class="col-6"><span><b>Template:</b></span></div>
                        <div class="col-6"><span>${this.reportDetail.template}</span></div>
                    </div>
                </div>
         `;
         
         this.elem.appendChild(row1);
         
         if(!this.isServiceProvider){
             let row2 = document.createElement("div");
             row2.classList.add("row");
             
             if(!this.isServiceProvider){
                 let activityDate = new Date(this.reportDetail.lastActivityDate);
                 
                 row2.innerHTML = `
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-6"></div>
                            <div class="col-6"></div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-6">
                                <span><b>Service Provider:</b></span>
                            </div>
                            <div class="col-6">
                                <span>${this.reportDetail.serviceProviderAddress.name}, ${this.reportDetail.serviceProviderAddress.addressLine1}${this.reportDetail.serviceProviderAddress.addressLine2 ? '' : ' ' + this.reportDetail.serviceProviderAddress.addressLine2}, ${this.reportDetail.serviceProviderAddress.city}, ${this.reportDetail.serviceProviderAddress.stateProvince} ${this.reportDetail.serviceProviderAddress.postalCode}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-6">
                                <span><b>Last Activity Type:</b></span>
                            </div>
                            <div class="col-6">
                                <span>${this.reportDetail.lastActivityType}</span>
                            </div>
                            <div class="col-6">
                                <span><b>Last Activity Date:</b></span>
                            </div>
                            <div class="col-6">
                                <span>${format(activityDate, "MM/dd/yyyy")}</span>
                            </div>
                        </div>
                    </div>
                 `;
             }
             else{
                 row2.innerHTML = `
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-6"></div>
                            <div class="col-6"></div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-6">
                                <span><b>Service Provider:</b></span>
                            </div>
                            <div class="col-6">
                                <span>${this.reportDetail.serviceProviderAddress.name}, ${this.reportDetail.serviceProviderAddress.addressLine1}${this.reportDetail.serviceProviderAddress.addressLine2 ? '' : ' ' + this.reportDetail.serviceProviderAddress.addressLine2}, ${this.reportDetail.serviceProviderAddress.City}, ${this.reportDetail.serviceProviderAddress.stateProvince} ${this.reportDetail.serviceProviderAddress.postalCode}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                        </div>
                    </div>
                 `;
             }
             
             this.elem.appendChild(row2);
         }
         if(this.reportDetail.ahjReviewedDate != null){
             let row3 = document.createElement("div");
             row3.classList.add("row");
             
             let ahjDate = new Date(this.reportDetail.ahjReviewedDate);
             
             row3.innerHTML = `
                     <div class="col-md-4">
                        <div class="row">
                            <div class="col-6"></div>
                            <div class="col-6"></div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-6">
                                <span>
                                    <b>AHJ Review Date:</b>
                                </span>
                            </div>
                            <div class="col-6">${format(ahjDate, "MM/dd/yyyy")}</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-6"></div>
                            <div class="col-6"></div>
                        </div>
                    </div>
             `;
             
             this.elem.appendChild(row3);
         }
         
         
    }
}

class DeficiencyListRenderObject{
    constructor(id, elem) {
        this.id = id;
        this.elem = elem;
    }
    
    createAndSet(){
        
    }
}