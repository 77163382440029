export class PhoneNumberBuilder{
    constructor(id, dotNetObjectReference, dotNetFunctionChange, dotNetFunctionInput) {
        this.id = id;
        this.elem = document.getElementById(this.id);
        this.dotNetObjectReference = dotNetObjectReference;
        this.dotNetFunctionChange = dotNetFunctionChange;
        this.dotNetFunctionInput = dotNetFunctionInput;

        const reference = this;

        this.elem.addEventListener('input', () => reference.formatInput());
        this.elem.addEventListener('change', () => reference.updateBlazor());
    }
    updateBlazor(){
        const inputField = this.elem;

        this.dotNetObjectReference.invokeMethodAsync(this.dotNetFunctionChange, inputField.value);
    }
    updateValue(val){
        const inputField = this.elem;
        inputField.value = this.conditionInput(val);
    }
    formatInput(){
        const inputField = this.elem;
        inputField.value = this.conditionInput(inputField.value);
        this.dotNetObjectReference.invokeMethodAsync(this.dotNetFunctionInput, inputField.value);
    }
    conditionInputForBlazor(val){
        return val.replace(/[^\d]/g, '');
    }
    conditionInput(val){
        if(val === undefined || val === null) return val;

        let trimmed = val.replace(/[^\d]/g, '');
        const trimmedLength = trimmed.length;

        if(trimmedLength < 4) return trimmed;

        if(trimmedLength < 7){
            return `(${trimmed.slice(0, 3)}) ${trimmed.slice(3)}`
        }

        if(trimmedLength < 11){
            return `(${trimmed.slice(0,3)}) ${trimmed.slice(3,6)}-${trimmed.slice(6)}`
        }

        return `(${trimmed.slice(0,3)}) ${trimmed.slice(3,6)}-${trimmed.slice(6,10)} x ${trimmed.slice(10,14)}`;
    }
}