export class IndexedDbWorkerWrapper{
    constructor(dotNetObj, serviceName){
        this.dotNetObj = dotNetObj;
        this.serviceName = serviceName;
        this.workerTerminated = true;
        this.blockingTask = false;
        this.queuedTasks = [];

        this.initWorker();
    }

    callQueuedTask(obj){
        if(obj.type === "read"){
            this.readData(obj.itemLocations);
        }
        else if(obj.type === "write"){
            this.sendData(obj.index, obj.data);
        }
        else if(obj.type === "delete"){
            this.deleteData(obj.itemLocations);
        }
        else if(obj.type === "clear"){
            this.clearData();
        }
        else if(obj.type === "cancel"){
            this.cancel();
        }
    }

    initWorker(){
        if(!this.workerTerminated){
            return;
        }

        let classRef = this;
        this.workerTerminated = false;
        this.worker = new Worker(new URL("./indexedDbLoader.js", import.meta.url));
    }

    cancel(){
        this.initWorker();
        this.worker.postMessage({
            type: 'cancel'
        });
    }

    sendData(index, data){
        this.initWorker();
        this.worker.postMessage({
            type: 'write',
            context: {
                serviceName: this.serviceName,
                index: index,
                data: data
            }
        });
    }
    readData(itemLocations){
        this.initWorker();
        this.worker.postMessage({
            type: 'read',
            context: {
                serviceName: this.serviceName,
                itemLocations: itemLocations
            }
        })
    }
    deleteData(itemLocations){
        this.initWorker();
        this.worker.postMessage({
            type: 'delete',
            context: {
                serviceName: this.serviceName,
                itemLocations: itemLocations
            }
        })
    }
    clearData(){
        this.initWorker();
        this.worker.postMessage({
            type: 'clear'
        })
    }

    runQueuedTask(){
        if(this.queuedTasks.length !== 0){
            let current = this.queuedTasks.shift();

            while(current !== undefined && !current.blocking){
                this.callQueuedTask(current);
                current = this.queuedTasks.shift();
            }
            this.blockingTask = false;

            if(current !== undefined){
                this.callQueuedTask(current);
            }

            return true;
        }

        return false;
    }

    abortWorker(){
        this.workerTerminated = true;
        this.worker.terminate();
    }

    conditionallyQueueTask(obj){
        if(this.blockingTask){
            this.queuedTasks.push(obj);
            return true;
        }

        return false;
    }
}