import axios from "axios";
import {ArcGISFullMapBuilder} from "./ComponentSource/ArcGISFullMapBuilder";
import {ArcGISMapBuilder} from "./ComponentSource/ArcGISMapBuilder";
import {CreditCardNumberBuilder} from "./ComponentSource/CreditCardNumberBuilder";
import {DatePickerBuilder} from "./ComponentSource/DatePickerBuilder";
import {DateRangePickerBuilder} from "./ComponentSource/DateRangePickerBuilder";
import {DeficientReportRenderObject} from "./ComponentSource/DeficientReportRenderObject";
import {DropdownListBuilder} from "./ComponentSource/DropDownListBuilder";
import {EsriFullMapBuilder} from "./ComponentSource/EsriFullMapBuilder";
import {EsriMapBuilder} from "./ComponentSource/EsriMapBuilder";
import {IndexedDbWorkerWrapper} from "./ComponentSource/IndexedDbWorkerWrapper";
import {ObservedObjectBuilder} from "./ComponentSource/ObservedObjectBuilder";
import {PieChartBuilder} from "./ComponentSource/ChartBuilder";
import {PhoneNumberBuilder} from "./ComponentSource/PhoneNumberBuilder";
import {PremisesCalendarBuilder} from "./ComponentSource/PremisesCalendarBuilder";
import {NumberMaskBuilder} from "./ComponentSource/NumberMaskBuilder";
import {ServiceProviderSelectJs} from "./ComponentSource/ServiceProviderSelectJs";
import {SignatureBuilder} from "./ComponentSource/SignatureBuilder";
import {StackedAreaChartBuilder} from "./ComponentSource/StackedAreaChartBuilder";
import {TooltipBuilder} from "./ComponentSource/TooltipBuilder";
import {ColumnAreaChartBuilder} from "./ComponentSource/ColumnAreaChartBuilder";
import {BarAreaChartBuilder} from "./ComponentSource/BarAreaChartBuilder";
import {PrintManager} from "./ComponentSource/PrintManager";


window.SetFocusToElement = function (element) {
    document.getElementById(element).focus();
};

window.MakePieChart = function (id, xName, yName, includeDataLabel, textName, title, pointColorName, includeLegend, legendPosition, legendAlignment) {
    return new PieChartBuilder(id, xName, yName, includeDataLabel, textName, title, pointColorName, includeLegend, legendPosition, legendAlignment);
}
window.MakeBarAreaChartPicker = function (id,xName,yName){
    return new BarAreaChartBuilder(id,xName,yName)
}
window.MakeColumnAreaChartPicker = function(id,xName,yName){
    return new ColumnAreaChartBuilder(id,xName,yName)
}
window.MakeFullMap = function (id, dotNetObj) {
    return new EsriFullMapBuilder(id, dotNetObj);
}


window.MakeArcGisMap = function (id, dotNetObj, geoFenceURL, ahjLatitude, ahjLongitude, accessToken, featureLayerURL) {
    return new ArcGISMapBuilder(id, dotNetObj, geoFenceURL, ahjLatitude, ahjLongitude, accessToken, featureLayerURL);
}

window.MakeFullArcGisMap = function (id, dotNetObj, geoFenceURL, ahjLatitude, ahjLongitude, reportTypeIdArray, reportTypeNameArray, accessToken, featureLayerURL) {
    return new ArcGISFullMapBuilder(id, dotNetObj, geoFenceURL, ahjLatitude, ahjLongitude, reportTypeIdArray, reportTypeNameArray, accessToken, featureLayerURL);
}

window.MakeStackedAreaChart = function (id, xName, yName, xAxisLabel, yAxisLabel, title) {
    return new StackedAreaChartBuilder(id, xName, yName, xAxisLabel, yAxisLabel, title);
}

window.hideSection = function (id){
    let elem = document.getElementById(id);

    if(elem != null && elem.parentElement !== null && elem.parentElement !== undefined){
        let nodes = Array.prototype.slice.call(elem.parentElement.children);

        let index = nodes.indexOf(elem);

        if(index !== -1){
            let i = index + 1;
            while(i < nodes.length && !nodes[i].classList.contains("section-identifier") && !nodes[i].classList.contains("ignore-identifier")){
                nodes[i].classList.add("d-none");
                i++;
            }
        }
    }
}

window.openSection = function (id){
    let elem = document.getElementById(id);

    if(elem != null && elem.parentElement !== null && elem.parentElement !== undefined){
        let nodes = Array.prototype.slice.call(elem.parentElement.children);

        let index = nodes.indexOf(elem);

        if(index !== -1){
            let i = index + 1;
            while(i < nodes.length && !nodes[i].classList.contains("section-identifier") && !nodes[i].classList.contains("ignore-identifier")){
                nodes[i].classList.remove("d-none");
                i++;
            }
        }
    }
}

window.PrintObj = function (obj) {
    console.log(obj);
}

window.PrintJson = function (json_string) {
}

window.CallReportCreate = function (dataModel, dotNetClass) {
    let authToken = window.ReadCookie('authToken');

    axios.get('https://tce-api-dev.thecomplianceengine.com/createreport', {
        headers: {
            'Authorization': `Bearer ${authToken}`
        },
        params: {
            PremisesId: dataModel.premiseId,
            ServiceProviderId: dataModel.serviceProviderId,
            CodeTypeId: dataModel.codeTypeId,
            AppName: 'PWA'
        },
        responseType: 'json'
    }).then(function (response) {

        dotNetClass.invokeMethodAsync('HandleReportCreateInit', response.data)
    })
}

window.CallGetReportById = function (id, dotNetClass) {
    let authToken = window.ReadCookie('authToken');

    axios.get('https://tce-api-dev.thecomplianceengine.com/report/show', {
        headers: {
            'Authorization': `Bearer ${authToken}`
        },
        params: {
            Id: id,
            AppName: 'PWA'
        },
        responseType: 'json'
    }).then(function (response) {
        let mainNode = response.data.RootDisplaySequenceNode

        dotNetClass.invokeMethodAsync('HandleReportGet', response.data.Report, mainNode.Children)
    })
}

window.PostReportCreate = function (postParams, dotNetClass) {
    let authToken = window.ReadCookie('authToken');

    axios.post('https://tce-api-dev.thecomplianceengine.com/createreport', {
        InspectionDateTime: postParams.inspectionDateTime,
        ReportTemplateId: postParams.reportTemplateId,
        InventoryId: postParams.inventoryId,
        TestKitId: postParams.testKitId,
        InventoryTemplateId: postParams.inventoryTemplateId,
        InventoryAttributeValues: postParams.inventoryAttributeValues,
        ServiceProviderId: postParams.serviceProviderId,
        ReportURL: postParams.reportURL,
        PremisesId: postParams.premisesId,
        AttachmentURLs: postParams.attachmentURLs,
        Deficiencies: postParams.deficiencies,
        DisplaySequenceNodes: postParams.displaySequenceNodes,
        ReferenceNumber: postParams.referenceNumber,
        InspectorIds: postParams.inspectorIds,
        AppName: 'PWA'
    }, {
        headers: {
            'Authorization': `Bearer ${authToken}`
        },
        responseType: 'json'
    }).then(function (response) {
        dotNetClass.invokeMethodAsync('HandleReportCreateFinalize', response.data)
    })
}

window.preventClick = function (elt) {
    elt.addEventListener("click", function (e) {
        e.preventDefault()
        e.stopPropagation();
    })
}

window.clickInput = function (id, elt) {
    elt.addEventListener('click', function () {
        let elem = document.getElementById(id);

        const event = new MouseEvent('click', {
            view: window,
            bubbles: false,
        });

        if ((elem !== null) && (elem !== undefined)) {
            elem.dispatchEvent(event);
        }
    });
}

window.MakeSignature = function (id, redoButton) {
    return new SignatureBuilder(id, redoButton);
}

window.getDbWorkerObject = function (serviceName, dotNetObject) {
    return new IndexedDbWorkerWrapper(dotNetObject, serviceName);
}

window.MakeDateRangePicker = function (id, format, start, depth, errorDivId, maxDate, openOnFocus) {
    return new DateRangePickerBuilder(id, format, start, depth, errorDivId, maxDate, openOnFocus);
}

window.MakeDatePicker = function (id, format, start, depth, errorDivId, maxDate, minDate, callObject, dotNetObject, dotNetFunctionName, placeholder) {
    return new DatePickerBuilder(id, format, start, depth, errorDivId, maxDate, minDate, callObject, dotNetObject, dotNetFunctionName, placeholder);
}

window.MakeEsriMap = function (id, dotNetObj, showDetails) {
    return new EsriMapBuilder(id, dotNetObj, showDetails);
}

window.SetRowClass = function (id) {
    let button = document.getElementById(id);
    let buttonDiv = button.parentElement;
    let td = buttonDiv.parentElement;
    let tr = td.parentElement;

    tr.classList.add("e-hide-row");
}
window.CloseToolTip = function (name) {
    const tooltip = document.getElementById(name);
    if (tooltip != null)
        tooltip.classList.add("tooltip-hidden");
}
window.DisplayToolTip = function (name) {
    const tooltip = document.getElementById(name);
    if (tooltip != null)
        tooltip.classList.remove("tooltip-hidden");

}
window.OpenToolTip = function (name) {
    const tooltip = document.getElementById(name);
    if (tooltip != null)
        tooltip.classList.remove("tooltip-hidden");

    document.addEventListener("click", handleClickOut, false);

    function handleClickOut(event) {
        try {
            if (event.target != null) {
                if (event.target.classList != null && !tooltip.contains(event.target) || event.target.classList.contains("e-tooltip-close")) {
                    CloseToolTip(name)
                    document.removeEventListener("click", handleClickOut, false);
                }
            }
        } catch (e) {
        }
    }
}

window.printDiv = function(divName, dotNetObj) {
    let printManager = new PrintManager(divName, dotNetObj, window);

    printManager.startPrint();
}

window.printDialogContent =function(dialogId) {
    var dialog = document.getElementById(dialogId);
    if (!dialog) return;

    var newWindow = window.open('', '', 'width=800,height=600');
    newWindow.document.write('<html><head><title>Receipt</title></head><body>');
    
    document.querySelectorAll('link[rel="stylesheet"]').forEach(function(link) {
        newWindow.document.write('<link rel="stylesheet" href="' + link.href + '">');
    });

    // Copy all inline styles
    document.querySelectorAll('style').forEach(function(style) {
        newWindow.document.write('<style>' + style.innerHTML + '</style>');
    });

    newWindow.document.write('</head><body>');

    // Clone the content from the dialog
    var clonedDialog = dialog.cloneNode(true);

    // Convert images to Base64 for printing
    var imgElements = clonedDialog.getElementsByTagName("img");
    for (var i = 0; i < imgElements.length; i++) {
        var img = imgElements[i];
        if (img.src.startsWith('http')) {
            try {
                var base64Image = getBase64Image(img);
                img.src = base64Image;
            } catch (error) {
                console.log("Error converting image to Base64:", error);
            }
        }
    }

    newWindow.document.body.appendChild(clonedDialog);

    // Add print and close buttons
    newWindow.document.write(`
        <div class="print-hide"  style="text-align: center; margin-top: 20px;">
            <button onclick="window.print()" style="padding: 5px; margin-right: 10px; font-size: 16px;">Print</button>
            <button onclick="window.close()" style="padding: 5px; font-size: 16px;">Close</button>
        </div>
    `);

    newWindow.document.write('</body></html>');
    newWindow.document.close();
}
function getBase64Image(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.naturalWidth;
    canvas.height = img.naturalHeight;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    return canvas.toDataURL("image/png");
}

window.buildPhoneFormatter = function (id, dotNetObjectReference, dotNetFunctionChange, dotNetFunctionInput) {
    return new PhoneNumberBuilder(id, dotNetObjectReference, dotNetFunctionChange, dotNetFunctionInput)
}

window.MaskInput = function (id, val) {
    let selectId = "#" + id;

    console.log(selectId);
    console.log(val);

    $(selectId).mask(val);
}

window.UnmaskInput = function (id) {
    let selectId = "#" + id;
    $(selectId).unmask();
}

window.ScrollToItem = function (id) {
    let item = document.getElementById(id);

    item.scrollIntoView({
        block: "center",
        inline: "center",
        behavior: "smooth"
    });
}

window.AddTooltipToElement = function (id, templateId) {
    return new TooltipBuilder(id, templateId);
}

window.OpenBlank = function (url) {
    window.open(url, "_blank");
}

window.BuildDropDown = function(id, placeholder, data, textName, valueName, dotNetObject, dotNetFunctionName){
    return new DropdownListBuilder(id, placeholder, data, textName, valueName, dotNetObject, dotNetFunctionName);
}

window.BuildObservedObject = function (id, dotNetObject, dotNetFunctionName, keyVal){
    return new ObservedObjectBuilder(id, dotNetObject, dotNetFunctionName, keyVal);
}

window.GetInputValueByComponentId = function(componentId){
    return document.getElementById(componentId)?.value ?? null
}

window.MakeDeficientReportObject = function(id, reportDetail, isServiceProvider, serviceProviderId){
    return new DeficientReportRenderObject(id, reportDetail, isServiceProvider, serviceProviderId);
}
window.buildCreditFormatter = function (id, placeholder, length, dotNetObjectReference, dotNetFunctionChange, dotNetFunctionInput) {
    return new CreditCardNumberBuilder(id, placeholder, length, dotNetObjectReference, dotNetFunctionChange, dotNetFunctionInput)
}

window.readAndSet = function(id, url, mimeType, dotNetObject, dotNetFunctionName){
    if(!id && !url){
        dotNetObject.invokeMethodAsync(dotNetFunctionName, true, false);
        return;
    }
    let elem = document.getElementById(id);
    fetch(url)
        .then(function(response) {
            dotNetObject.invokeMethodAsync(dotNetFunctionName, response.status === 200, response.status === 403);
            return response.arrayBuffer();
        })
        .then(function(buffer) {
            // Convert buffer to Base64 safely
            let uint8Array = new Uint8Array(buffer);
            let chunkSize = 8192; // Setting a reasonable chunk size
            let base64String = '';
            for (let i = 0; i < uint8Array.length; i += chunkSize) {
                let chunk = uint8Array.slice(i, i + chunkSize);
                base64String += String.fromCharCode.apply(null, chunk);
            }
            base64String = btoa(base64String);
            if (base64String) {
                elem.src = `data:${mimeType};base64,${base64String}`;
            } else {
                elem.style.display = 'none';
            }

            dotNetObject.invokeMethodAsync(dotNetFunctionName, true, false);
        })
        .catch(function(error) {
            console.log("Error fetching image:", error);
            dotNetObject.invokeMethodAsync(dotNetFunctionName, false, false);
            elem.style.display = 'none';
        });
}

window.maskBoxFocus = (id, index) => {
    var maskObj = document.getElementById(id);
    maskObj.selectionStart = index;
    maskObj.selectionEnd = index;
}

window.downloadFile = function (name, url) {
    if (!url) {
        return;
    }

    const downloadLink = document.createElement("a");
    downloadLink.download = name;
    downloadLink.href = url;
    downloadLink.target = "_blank";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

window.clearInput = function (inputId) {
    setTimeout(function () {
        var input = document.getElementById(inputId);
        if (input) {
            input.value = "";
        }
    }, 30);
}

window.disableScroll = function () {
    const x = window.scrollX;
    const y = window.scrollY;
    window.onscroll = () => window.scrollTo(x,y)
}

window.enableScroll = function () {
    window.onscroll = null
}

window.ScrollToTop = function(){
    scrollTo({top: 0, behavior: "smooth"});
}

window.DelayedScrollToTop = function(){
    setTimeout(() => {
        scrollTo({top: 0, behavior: "smooth"})
    }, 500);
}

window.ScrollDialogToTop = function(dialogId) {
    var dialog = document.getElementById(dialogId);
    
    if (dialog) {
        dialog.scrollTo({ top: 0, behavior: "smooth" });
    }
};

window.buildFormatter = function(id, placeholder, length, dotNetObjectReference, dotNetFunctionChange, dotNetFunctionInput) {
    return new NumberMaskBuilder(id, placeholder, length, dotNetObjectReference, dotNetFunctionChange, dotNetFunctionInput);
}

window.MakeServiceSelect = function (id, textName, valueName, placeholder, dotNetObject, dataFunctionName, changeEventName, showSpinnerName, hideSpinnerName, showClearButtonName, hideClearButtonName, initialValue){
    return new ServiceProviderSelectJs(id, textName, valueName, placeholder, dotNetObject, dataFunctionName, changeEventName, showSpinnerName, hideSpinnerName, showClearButtonName, hideClearButtonName, initialValue);
}

window.PremisesCalendar = function(id, dotNetObj) {
    console.log("found id: " + id);
    let calendarEl = document.getElementById(id);
    return new PremisesCalendarBuilder(calendarEl, dotNetObj);
}

window.addBorderLoading = function(id, buttonColor) {
    const idElem = document.getElementById(id)
    const borderLoading = 'rotate-border-' + buttonColor;

    if (idElem != null && idElem.classList != null && !idElem.classList.contains(borderLoading)) {
        idElem.classList.add(borderLoading)
    }
}

window.removeBorderLoading = function(id, buttonColor) {
    const idElem = document.getElementById(id)
    const borderLoading = 'rotate-border-' + buttonColor;

    if (idElem != null && idElem.classList != null && idElem.classList.contains(borderLoading)) {
        idElem.classList.remove(borderLoading)
    }
}

window.localStorageHelper = {
    clear: function() {
        //if we ever decide to expand on session storage or local storage we will to consider how we handle de-impersonating and impersonating
        localStorage.clear();
        sessionStorage.clear();
    }
};

window.checkDatePickerValidFormat = function(id) {
    const idElem = document.getElementById(id);

    if (idElem === null || idElem === undefined) {
        return true;
    }

    const parentClassList = idElem.parentElement.classList;
    if (parentClassList.contains("e-error")) {
        return false;
    } else {
        return true;
    }
}

window.forceSpanContent = function(id, value){
    let elem = document.getElementById(id);

    let children = Array.prototype.slice.call(elem.children);

    if(children !== null && children.length !== 0){
        let child = children.find((child) => {
            return child.classList.contains("e-multiselect");
        });

        if(child !== null){
            let childsChildren = Array.prototype.slice.call(child.children);

            if(childsChildren !== null && childsChildren.length !== 0){
                let childsChild = childsChildren.find((child) => {
                    return child.classList.contains("e-multi-select-wrapper");
                });

                if(childsChild !== null){
                    let childsChildChildren = Array.prototype.slice.call(childsChild.children);

                    if(childsChildChildren !== null && childsChildChildren.length !== 0){
                        let childsChildChild = childsChildChildren.find((child) => {
                            return child.classList.contains("e-delim-values");
                        });

                        if(childsChildChild !== null){
                            setTimeout(() => {childsChildChild.innerHTML = value;}, 100)
                        }
                    }
                }
            }
        }
    }
}

window.BuildDropDown = function(id, placeholder, data, textName, valueName, dotNetObject, dotNetFunctionName){
    return new DropdownListBuilder(id, placeholder, data, textName, valueName, dotNetObject, dotNetFunctionName);
}

window.BuildObservedObject = function (id, dotNetObject, dotNetFunctionName, keyVal){
    return new ObservedObjectBuilder(id, dotNetObject, dotNetFunctionName, keyVal);
}

window.MakeDeficientReportObject = function(id, reportDetail, isServiceProvider, serviceProviderId){
    return new DeficientReportRenderObject(id, reportDetail, isServiceProvider, serviceProviderId);
}

window.localStorageHelper = {
    clear: function() {
        //if we ever decide to expand on session storage or local storage we will to consider how we handle de-impersonating and impersonating
        localStorage.clear();
        sessionStorage.clear();
    }
};

window.checkDatePickerValidFormat = function(id) {
    const idElem = document.getElementById(id);
    
    if (idElem === null || idElem === undefined) {
        return true;
    }
    
    const parentClassList = idElem.parentElement.classList;
    if (parentClassList.contains("e-error")) {
        return false;
    } else {
        return true;
    }
}
window.getFirstHash = function() {
    if (window.location.hash) {
        
        const ids = window.location.hash.split('#').filter(Boolean);
        return ids.length > 0 ? ids[0] : null; 
    }
    return null;
};
window.scrollToHash = function(ShowTab) {
    if (window.location.hash) {
        // Split the hash by `#` to account for multiple IDs (skip the first empty part)
        const ids = window.location.hash.split('#').filter(Boolean);
        ids.forEach(id => {
            console.log(id);
            const tabPane = document.getElementById(id);
            console.log(tabPane);
            if (tabPane) {
                // Find the tab link and activate it
                
                const tabLink = document.querySelector(`[data-bs-target='#${id}']`);
                if (tabLink && ShowTab===true) {
                    try {
                        const tabInstance = new bootstrap.Tab(tabLink);
                        tabInstance.show();
                    } catch (e) {
                        console.error("Error activating tab:", e);
                    }
                }

                if(ShowTab === false) {
                    // Scroll to the element to bring it into view
                    tabPane.scrollIntoView({behavior: 'smooth'});
                }
            }
        });
    }
}

//Configures the Gtag, adding the UserIds to the collect. 
// Can be called multiple times, but resets nothing after the initial config
window.configGtag = function(user_id) {
    window.dataLayer = window.dataLayer || [];
    gtag('config', 'G-STNMEZTNZN',
        { 'user_id': user_id,
            'tce_user_id': user_id});
    gtag('js', new Date());
}